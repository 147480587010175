<template>
  <div data-app>
    <v-card>
      <v-card-title> Maintenance calendar </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-spacer />
            <v-col cols="10" sm="8" md="9">
              <v-radio-group class="float-right" v-model="filterOptions" row>
                <v-radio label="New" :value="0"></v-radio>

                <v-radio label="In progress" :value="5"></v-radio>

                <v-radio label="Completed" :value="10"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="fill-height">
            <v-col>
              <v-sheet height="64">
                <v-toolbar flat color="white">
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Today
                  </v-btn>
                  <!-- <v-spacer></v-spacer> -->
                  <v-spacer />
                  <v-toolbar-title>{{ title }}</v-toolbar-title>
                  <v-spacer />
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="events"
                  :event-color="getEventColor"
                  :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                  :now="today"
                  :type="type"
                  :loader="loadingTable"
                  @click:event="handleClickItem"
                  @change="updateRange"
                ></v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import i18nService from "@/core/services/i18n.service.js";

import ApiService from "@/core/services/api.service";

export const ENDPOINT = "maintenance/maintenance";

export const PERMISSION_TO = "maintenance.equipment.";

export default {
  name: "Calendar",

  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "equipments",
      routePath: "/maintenance/calendar/",
      permissionTo: PERMISSION_TO,

      cardTitle: "Equipments",
      search: "",
      permissions: JSON.parse(localStorage.permissions),
      maintenances: [],
      modalData: {
        dialog: false,
        editedId: null,
      },
      filterOptions: 0,

      dialogDelete: false,
      loadingTable: true,
      endPoint: ENDPOINT,
      focus: "",
      type: "month",
      start: null,
      end: null,
      eventColors: [
        "#0466c8",
        "#0353a4",
        "#023e7d",
        "#002855",
        "#26223c",
        "#001233",
        "#33415c",
        "#fc677d",
        "#7d8597",
        "#979dac",
      ],
    };
  },

  computed: {
    ...mapGetters(["equipmentCollection", "statuses", "employeeCollection"]),

    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },

    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },

    events() {
      return this.maintenances
        .map((item) => {
          item.name = item.equipment_name;
          item.start = item.date;
          item.end = item.date;
          item.color = this.eventColors[item.maintenance_id.toString().at(-1)];
          item.timed = false;
          return item;
        })
        .filter((item) => item.status == this.filterOptions);
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchEquipment", "fetchEmployee"]),

    getEventColor(event) {
      return event.color;
    },

    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },

    updateRange({ start, end }) {
      // const events = [];

      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      // const days = (max.getTime() - min.getTime()) / 86400000;
      // const eventCount = this.rnd(days, days + 20);

      // for (let i = 0; i < eventCount; i++) {
      // const allDay = true;
      // const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      // const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      // const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      // const second = new Date(first.getTime() + secondTimestamp);
      // events.push({
      //   name: this.names[this.rnd(0, this.names.length - 1)],
      //   start: this.formatDate(first, !allDay),
      //   end: this.formatDate(second, !allDay),
      //   color: this.colors[this.rnd(0, this.colors.length - 1)],
      // });
      // }

      this.start = start;
      this.end = end;
      // this.events = events;
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    fetchMaintance() {
      return ApiService.get(ENDPOINT)
        .then(({ data }) => {
          let appLocale = i18nService.getActiveLanguage();
          let dataLocalized = data.map((item) => {
            if (!item.equipment.equipment_name) {
              if (item.equipment.equipment_translations) {
                item.equipment_name = item.equipment.equipment_translations[
                  appLocale
                ]
                  ? item.equipment.equipment_translations[appLocale].name
                  : item.equipment.equipment_translations[0].name;
              }
            } else {
              item.equipment_name = item.equipment.equipment_name;
            }

            return item;
          });
          this.maintenances = dataLocalized;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleClickItem(item) {
      if (item.event && this.permissionCan("view")) {
        // this.$router.push(`/maintenance/equipments/${item.event.equipment_id}`);
        window.open(`/#/maintenance/equipments/${item.event.equipment_id}`);
      }
    },

    fetchModel() {
      // this.fetchEmployee();
      // this.fetchEquipment();
      return this.fetchMaintance();
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
    if (this.$refs.calendar) this.$refs.calendar.checkChange();
  },
};
</script>
